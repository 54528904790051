import {
  Button,
  Flex,
  FlexWithGap,
  Heading,
  IconSize,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CryptoDefenderLogo } from '../common/CryptoDefenderLogo';
import { MainContainer } from '../common/MainContainer';
import { RulesModal } from '../common/RulesModal';
import { Routes } from '../routes/routes';

export const DashboardContent: FC = () => {
  const { isMobile } = useMatchBreakpoints();
  const navigate = useNavigate();
  const [openRules, setOpenRules] = useState<boolean>(false);

  useEffect(() => {
    if (openRules && !isMobile) document.body.style.overflowY = 'hidden';
    else document.body.style.overflowY = 'scroll';
  }, [openRules]);

  return (
    <MainContainer width={isMobile ? '90%' : '590px'}>
      <ContentWrapper>
        {openRules && <RulesModal onClose={() => setOpenRules(false)} />}
        <FlexWithGap
          gap={`${isMobile ? 20 : 32}px`}
          flexDirection="column"
          alignItems="center"
        >
          <CryptoDefenderLogo size={isMobile ? 100 : 224} />
          <Heading scale={'xxl'} color="typography.input">
            God-Mode For Crypto
          </Heading>
        </FlexWithGap>
        <FlexWithGap
          gap="36px"
          width={'400px'}
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent={'center'}
        >
          <Button
            size={IconSize.L}
            secondary
            style={{ padding: '6px 24px', width: 'fit-content' }}
            onClick={() => setOpenRules(true)}
          >
            Rules
          </Button>
          <Button
            size={IconSize.L}
            secondary
            style={{ padding: '6px 24px', width: 'fit-content' }}
            onClick={() => navigate(Routes.HallOfFame)}
          >
            Hall Of Fame
          </Button>
        </FlexWithGap>
        <FlexWithGap
          gap={`28px`}
          width={isMobile ? '180px' : '300px'}
          flexDirection="column"
          border="2px solid white"
        >
          <Button
            size={IconSize.L}
            primary
            style={{ width: '100%' }}
            onClick={() => navigate(Routes.Details)}
          >
            Start
          </Button>
        </FlexWithGap>
      </ContentWrapper>
    </MainContainer>
  );
};

const ContentWrapper = styled(Flex)`
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  padding: 60px 0;
`;
