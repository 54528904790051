import {
  DarkInfoBox,
  FlowCard,
  Heading,
  LargeText,
  lightColors,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import ETHIcon from '@bumper-dao/ui-kit/dist/images/48px/tokens/eth.png';
import React, { FC } from 'react';

import {
  CoinKey,
  CoinName,
  ContentHeadingWrapper,
  ContentValueWrapper,
  ContentWrapper,
  InfoBoxContent,
} from './styles';
import BottomLeftCornerAsset from './svg/leftAsset.svg';
import BottomRightCornerAsset from './svg/rightAsset.svg';
import { WalletCardProps } from './types';

export const WalletCard: FC<WalletCardProps> = (props) => {
  const { isMobile } = useMatchBreakpoints();
  return (
    <FlowCard
      header={<Header />}
      content={<Content {...props} />}
      bottomLeftCorner={BottomLeftCornerAsset}
      bottomRightCorner={BottomRightCornerAsset}
      radiatorWidth="36px"
      bottomLedgeHeight="38px"
      bottomLedgeCenterWidth={isMobile ? '240px' : '1030px'}
      mt="4rem"
      mb="3rem"
    />
  );
};

const Content: FC<WalletCardProps> = ({ total, price, percent, value }) => {
  return (
    <ContentWrapper>
      <DarkInfoBox minWidth="150px" minHeight="68px">
        <InfoBoxContent>
          <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
            Total
          </Heading>
          <ContentValueWrapper>
            <LargeText color={lightColors.secondary.white}>{total}</LargeText>
            <CoinKey>ETH</CoinKey>
          </ContentValueWrapper>
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox minWidth="150px" minHeight="68px">
        <InfoBoxContent>
          <ContentHeadingWrapper>
            <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
              Value
            </Heading>
            <Tooltip tooltipValue={'fdsfdsg'} />
          </ContentHeadingWrapper>
          <LargeText color={lightColors.secondary.white}>${value}</LargeText>
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox minWidth="150px" minHeight="68px">
        <InfoBoxContent>
          <ContentHeadingWrapper>
            <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
              ETH Price
            </Heading>
            <Tooltip tooltipValue={'fdsfdsg'} />
          </ContentHeadingWrapper>
          <LargeText color={lightColors.secondary.white}>${price}</LargeText>
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox minWidth="150px" minHeight="68px">
        <InfoBoxContent>
          <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
            Last 7 days
          </Heading>
          <LargeText color={lightColors.typography.input}>{percent}</LargeText>
        </InfoBoxContent>
      </DarkInfoBox>
    </ContentWrapper>
  );
};

const Header = () => {
  const { isMobile } = useMatchBreakpoints();
  return (
    <ContentWrapper
      justifyContent="start !important"
      ml={isMobile ? '0' : '-80px'}
      zIndex={99}
    >
      <CoinName>
        <img src={ETHIcon} width="48px" />
        <LargeText
          fontSize="18px"
          lineHeight="150%"
          letterSpacing="0.03em"
          color={lightColors.secondary.white}
        >
          Ether
        </LargeText>
        <CoinKey style={{ marginLeft: '-10px' }}>(ETH)</CoinKey>
      </CoinName>
    </ContentWrapper>
  );
};
