import {
  Box,
  Close,
  Flex,
  FlexWithGap,
  Heading,
  IconSize,
  LargeText,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { FC } from 'react';
import styled from 'styled-components';

import { CryptoDefenderLogo } from './CryptoDefenderLogo';
import { ModalWrapper, PixelatedContainer } from './MainContainer';

import { STARTING_BALANCE_ETH } from '../../commons/config';
import { RulesModalProps } from '../Dashboard/types';

export const RulesModal: FC<RulesModalProps> = ({ onClose }) => {
  const { isMobile } = useMatchBreakpoints();
  return (
    <ModalWrapper>
      <RulesContainer>
        <CloseButtonContainer onClick={onClose}>
          <Close variant={IconSize.L} />
        </CloseButtonContainer>
        <CryptoDefenderLogo size={100} />
        <Box height={isMobile ? '80vh' : '100%'} overflowY="scroll">
          <FlexWithGap
            gap="12px"
            flexDirection="column"
            alignItems="center"
            height={isMobile ? 'fit-content' : '100%'}
            padding={isMobile ? '12px' : '0'}
          >
            <Heading scale={'xl'} color="typography.input">
              Rules:
            </Heading>
            <LargeText color="white">
              Defending your wallet means going back in time in crypto history.
              Use Bumper to protect your crypto from the alien volatility
              hoards, and then return back to the future to see how much you
              would have saved.
            </LargeText>
            <StyledUl>
              <li>
                Crypto Defender blasts you back in time with{' '}
                {STARTING_BALANCE_ETH} ETH to protect
              </li>
              <li>
                Protect your ETH as follows :<br></br>
                <StyledOl>
                  <li>Select the Amount of ETH to protect (max. 10 ETH).</li>
                  <li>
                    Select the Price Floor you want to protect at (eg. 90% of
                    $10,000 worth of ETH means $9,000 will be protected).
                  </li>
                  <li>Select the Term length of your protection policy.</li>
                </StyledOl>
              </li>
              <li>Press Go And Let The Market Do Its Thing...</li>
            </StyledUl>
          </FlexWithGap>
          <Box p="30px 8px">
            <WinRuleContainer>
              The winner is the player with the largest ratio of the protected
              value to the original protected amount. Win celestial prizes and
              be crowned the champion Crypto Defender
            </WinRuleContainer>
          </Box>
        </Box>
      </RulesContainer>
    </ModalWrapper>
  );
};

const RulesContainer = styled(PixelatedContainer)`
  width: 708px;
  height: 80vh;
  position: relative;

  align-items: center;

  ${({ theme }) => theme.mediaQueries.sm} {
    gap: 20px;
    width: 90%;
`;

const CloseButtonContainer = styled(Box)`
  top: 16px;
  right: 12px;
  position: absolute;
  cursor: pointer;
`;

const StyledUl = styled('ul')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  padding-left: 20px;
  list-style-type: disc;
  list-style-position: outside;
  color: ${({ theme }) => theme.colors.typography.input};
  font-family: Roboto Mono;
  font-size: 16px;
  line-height: 24px;
`;

const StyledOl = styled('ol')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  padding-left: 30px;
  padding-top: 5px;
  list-style-type: decimal;
  list-style-position: outside;
  color: ${({ theme }) => theme.colors.typography.input};
  font-family: Roboto Mono;
  font-size: 16px;
  line-height: 24px;
`;

export const WinRuleContainer = styled(Flex)`
  width: 100%;
  padding: 16px 13px;
  align-items: center;
  justify-content: center;
  font-family: Share Tech Mono;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary.green};
  background-color: ${({ theme }) => theme.colors.primary2.darkSlateGray};
  box-shadow: 4px 0px 0px 0px
      ${({ theme }) => theme.colors.primary2.darkSlateGray},
    0px 4px 0px 0px ${({ theme }) => theme.colors.primary2.darkSlateGray},
    -4px 0px 0px 0px ${({ theme }) => theme.colors.primary2.darkSlateGray},
    0px -4px 0px 0px ${({ theme }) => theme.colors.primary2.darkSlateGray},
    -8px 0px 0px 0px ${({ theme }) => theme.colors.primary2.deepTeal},
    8px 0px 0px 0px ${({ theme }) => theme.colors.primary2.deepTeal},
    0px 8px 0px 0px ${({ theme }) => theme.colors.primary2.deepTeal},
    0px -8px 0px 0px ${({ theme }) => theme.colors.primary2.deepTeal},
    0px 0px 0px 4px ${({ theme }) => theme.colors.primary2.deepTeal};

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 16px 24px;
  }
`;
