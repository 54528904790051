import {
  Box,
  Close,
  Heading,
  IconSize,
  LargeText,
  MediumText,
  SmallText,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { FC } from 'react';

import { CloseButtonContainer, Container } from './styles';
import { OfficialRulesModalProps } from './types';

import { InlineLink } from '../../Details/DetailsContent';
import { CryptoDefenderLogo } from '../CryptoDefenderLogo';
import { ModalWrapper } from '../MainContainer';

export const OfficalRulesModal: FC<OfficialRulesModalProps> = ({
  onCloseModal,
}) => {
  const { isMobile } = useMatchBreakpoints();

  return (
    <ModalWrapper>
      <Container>
        <CloseButtonContainer onClick={onCloseModal}>
          <Close variant={IconSize.L} />
        </CloseButtonContainer>

        <Box paddingY={'20px'}>
          <CryptoDefenderLogo size={isMobile ? 100 : 50} />
        </Box>

        <Box
          height={'100%'}
          overflowY="auto"
          paddingX={'10px'}
          paddingY={'20px'}
        >
          <Heading
            scale={'xl'}
            color="secondary.white"
            width={'100%'}
            paddingBottom="20px"
            textAlign={isMobile ? 'center' : 'center'}
          >
            Official Contest Rules
          </Heading>

          <MediumText color="white">
            {
              'NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. ALL FEDERAL, STATE, LOCAL, AND MUNICIPAL LAWS AND REGULATIONS APPLY. VOID WHERE PROHIBITED. PARTICIPATION IN THE CONTEST CONSTITUTES YOUR AGREEMENT TO AND ACCEPTANCE OF THE OFFICIAL RULES (“RULES”) BELOW.'
            }
          </MediumText>

          <SmallText color="white">
            AN AFFIDAVIT OF ELIGIBILITY/ PRIZE ACCEPTANCE DOCUMENTS MAY BE
            REQUIRED. All disputes will be resolved solely by binding
            arbitration. You may opt-out of arbitration by sending an email to
            support@bumper.fi within 30 days of your initial participation in
            this contest.
          </SmallText>

          <SmallText color="white">
            {
              ' SPONSOR. This contest is sponsored by Bumper (“Sponsor”). References to Sponsor in this Agreement shall refer solely to the company sponsoring the contest and, its parent company affiliates, subsidiaries and respective employees, officers and directors.'
            }
          </SmallText>

          <LargeText py={15} fontWeight="bold" color="white">
            Eligibility
          </LargeText>
          <SmallText color="white">
            This Sponsor Contest (the “Contest”) is open to individuals who are
            at least 18 years of age or older and legal age of majority in their
            country of residence. Employees of Sponsor and other companies
            associated with the promotion of the Contest, and their respective
            parents, subsidiaries, affiliates and advertising and promotion
            agencies as well as the immediate family (spouse, parents, siblings,
            and children) and household members of each such employee are not
            eligible. Individuals entering the Contest confirm they are not
            legally prohibited from doing so in their country of residence. The
            Contest is subject to federal, state, and local laws and
            regulations.
          </SmallText>

          <LargeText py={15} fontWeight="bold" color="white">
            Agreement to Rules
          </LargeText>
          <SmallText color="white">
            Participation in the Contest constitutes your agreement to and
            acceptance of these Rules, the{' '}
            <InlineLink
              href={'https://www.bumper.fi/terms-and-conditions'}
              target="_blank"
            >
              Bumper Terms and Conditions
            </InlineLink>
            , and the decisions of the Sponsor, which are final and binding.
            Winning a prize is contingent upon being and staying compliant with
            these Rules.
          </SmallText>

          <LargeText py={15} fontWeight="bold" color="white">
            Contest Period
          </LargeText>
          <SmallText color="white">
            {
              'The Contest begins on June 10, 2022 at 12pm UTC and ends on June 22, 2022 at 12pm UTC (the “Contest Period”). Entries that are submitted before or after the Contest Period will be disqualified. These dates may be changed; any changes will be announced by the Twitter account of the Sponsor (@bumperfinance).'
            }
          </SmallText>

          <LargeText py={15} fontWeight="bold" color="white">
            How to Enter
          </LargeText>
          <SmallText color="white">
            {
              "Sign up to, and play, Bumper's Crypto Defender via the competition site - https://defender.bumper.fi. By entering, you agree to receive communications via email from Bumper about key announcements and relevant news. There is no fee to sign up. There is no limit to the number of times you may play Crypto Defender for the duration of the Contest Period."
            }
          </SmallText>
          <LargeText py={15} fontWeight="bold" color="white">
            Prizes
          </LargeText>
          <SmallText color="white">
            The Sponsor will maintain a database of all participants’ total
            wallet value after the participant has played Crypto Defender, and a
            ‘Hall of Fame’ leaderboard will be publicly outputted. Prizes will
            be allocated to the top 3 positions on the Hall of Fame at the
            conclusion of the competition. At 12pm UTC on June 22, 2022, the
            Sponsor will select the winners from the eligible Entries.
            <ul>
              <li>1st position - $1,000 in USDC</li>
              <li>2nd position - $500 in USDC</li>
              <li>3rd position - $250 in USDC</li>
              <li>Top 5 Sharers: $50 in USDC each</li>
            </ul>
            <br></br>
            {
              'Where there are multiple participants who achieve the same final result, and therefore position on the Hall of Fame, prizes will be awarded to the participant who submitted their score first. A further prize pool of $250 USDC may be offered to up to 5 participants by the Sponsor for sharing their score after playing Crypto Defender. Prizes are non-transferable and no substitution will be made except as provided at the Sponsor’s sole discretion. Participants waive the right to assert as a cost of winning a prize, any costs associated with claiming or seeking to claim a prize, or using a prize. Each winner is solely responsible for reporting and paying any and all applicable taxes related to the prizes(s). Disclosure: The value of crypto assets is subject to change. It can go up or down and there can be a substantial risk that it could lose value (possibly all value).'
            }
          </SmallText>

          <LargeText py={15} fontWeight="bold" color="white">
            Winner Notification
          </LargeText>
          <SmallText color="white">
            This section is only applicable to parties that qualify for a prize.
            If you are a prize winner, the Sponsor will be notified by email
            after June 22, 2022. You will be required to respond to us within
            five (5) days of the date of notice or attempted notice is sent, in
            order to claim your prize. If you cannot be contacted, or you fail
            to respond appropriately within the required time period, or the
            prize is returned as undeliverable, you forfeit your prize. If you
            are at least eighteen (18) but are still considered a minor in your
            jurisdiction of residence, the Sponsor reserves the right to place
            the prize in the name of your parent or legal guardian, who will be
            required to sign the Declaration of Claim on your behalf and fulfill
            any other requirements imposed on you set forth herein. Receiving a
            prize of any type is contingent upon fulfilling all requirements and
            compliance with these Rules. In the event that a prize recipient is
            disqualified for any reason, the Sponsor is not obliged to provide
            an alternate prize, or extend the Contest Period in any way.
          </SmallText>

          <LargeText py={15} fontWeight="bold" color="white">
            General Conditions
          </LargeText>
          <SmallText color="white">
            In the event that the operation, security, or administration of the
            Contest is impaired in any way for any reason, including, but not
            limited to fraud, technical problems, security malfunctions or in
            the event the Contest is unable to run as planned for any other
            reason, as determined by Sponsor in its sole discretion, the Sponsor
            may, in its sole discretion, either: (1) suspend the Contest to
            address the impairment and then resume in a manner that best
            conforms to the spirit of these Rules; or (2) terminate the Contest
            and, in the event of termination, no participant shall be eligible
            for a prize of any type.
            <br></br>
            <br></br>
            Sponsor reserves the right in its sole discretion to disqualify any
            individual it finds to be tampering with the operation of the
            Contest, using bots, or to be acting in violation of these Rules or
            in a disruptive manner. Any attempt by any person to damage the
            website or undermine the legitimate operation of the Contest may be
            a violation of criminal and civil law, and, should such an attempt
            be made, Sponsor reserves the right to seek damages (including
            attorneys fees) and any other remedies from any such person to the
            fullest extent permitted by law. Failure by Sponsor to enforce any
            provision of these Official Rules shall not constitute a waiver of
            that provision.
          </SmallText>

          <LargeText py={15} fontWeight="bold" color="white">
            Release and Limitations of Liability
          </LargeText>
          <SmallText color="white">
            {
              'By participating in the Contest, you agree to release and hold harmless the Sponsor, and each of its respective parents, subsidiaries, affiliates, respective officers, directors, employees, shareholders, representatives, advertising and promotion agencies, and other companies associated with the Contest, and each of their respective officers, directors, employees, shareholders, representatives, and agents (together, “Released Parties”), from and against any claim or cause of action arising out of participation in the Contest or receipt of a Reward, including, but not limited to: (a) any technical errors associated with the Contest, including lost, interrupted or unavailable Internet Service Provider (ISP), network, server, or wireless service provider; (b) unauthorized human intervention in the Contest; (c) mechanical human, printing or typographical errors; or (d) injury, death, losses or damages of any kind, to persons or property which may be caused, directly or indirectly, in whole or in part, from your participation in the Contest or acceptance, receipt or misuse of any Reward you may receive. You further agree that in any cause of action, the Released Parties’ liability will be limited to the cost of entering and participating in the Contest, and in no event shall the entrant be entitled to receive attorney’s fees. Released Parties are also not responsible for any incorrect or inaccurate information, whether caused by site users, tampering, hacking, or by any equipment or programming associated with or utilized in the Contest.'
            }
          </SmallText>

          <LargeText py={15} fontWeight="bold" color="white">
            {'Privacy & Information Submitted'}
          </LargeText>
          <SmallText color="white">
            {
              'As a condition of entering the Contest, you give consent for Sponsor to obtain and deliver his or her name, address and other information to third parties for the purpose of administering this Contest and to comply with applicable laws, regulations and rules.  Any information you provide to Sponsor may be used to communicate with you in relation to this Contest or on a Contest Winner’s list.  Information submitted in connection with the Project will be treated in accordance with the Sponsor’s Privacy Policy.'
            }
          </SmallText>

          <LargeText py={15} fontWeight="bold" color="white">
            Miscellaneous
          </LargeText>
          <SmallText color="white">
            The invalidity or unenforceability of any provision of these Rules
            or the prize acceptance documents will not affect the validity or
            enforceability of any other provision. In the event that any
            provision of the Rules is determined to be invalid or otherwise
            unenforceable or illegal, the other provisions will remain in effect
            and will be construed in accordance with their terms as if the
            invalid or illegal provision were not contained herein. The
            Sponsor’s failure to enforce any term of these Rules will not
            constitute a waiver of that provision. You agree to waive any rights
            to claim ambiguity of these Rules. Headings are solely for
            convenience of reference and will not be deemed to affect in any
            manner the meaning or intent of the documents or any provision
            hereof. In the event there is a discrepancy or inconsistency between
            disclosures or other statements contained in any Contest-related
            materials, privacy policy or terms of use on the Site and/or the
            terms and conditions of the Rules, the Rules shall prevail, govern
            and control.
          </SmallText>

          <LargeText py={15} fontWeight="bold" color="white">
            {' '}
            Arbitration
          </LargeText>
          <SmallText color="white">
            These Rules shall be governed by, and construed in accordance with,
            the laws of the England and Wales, without giving effect to any
            choice of law or conflict of law rules, which would cause the
            application of the laws of any jurisdiction other than England and
            Wales. Except where prohibited, you agree that any and all disputes,
            claims and causes of action arising out of, or connected with, the
            Contest or any prize awarded shall be resolved by binding
            arbitration, without resort to any form of class action, and
            exclusively by the appropriate court located in England and Wales.
            Arbitration proceedings shall take place in the jurisdiction of
            England and Wales. Each party is responsible for paying its own
            filing, administrative and arbitrator fees. Judgment on the
            arbitration award may be entered in any court having jurisdiction
            thereof.
          </SmallText>

          <br></br>
          <br></br>

          <SmallText color="white">
            {'DISCLAIMER'}
            <br></br>
            {
              'This Promotion & Sweepstakes is for information purposes only. It does not constitute investment advice, recommendations, or any form of solicitation. Through the Promotion & Sweepstakes, the Company does not grant any rights, express or implied, other than the right to participate in the Promotion & Sweepstakes. In particular, the Company expressly denies any ability of a Customer to represent or confer any ownership right or stake, share, security, or equivalent rights, or any right to receive future revenue shares, intellectual property rights or any other form of participation in or relating to any Company product and/or any of its corporate affiliates. The Company reserves the right to reject any submission. The Company rejects any responsibility for any direct or consequential loss or damage of any kind whatsoever arising directly or indirectly from: (i) reliance on any information provided, (ii) any error, omission or inaccuracy in any such information; or (iii) any action resulting from such information.'
            }{' '}
            <br></br>
            <br></br>
            {
              'PUBLICITY RELEASE: By entering the Sweepstakes, you agree to Sponsor’s use of your personal information as described in Sponsor’s Privacy Policy and as described in these Official Rules. By participating in the Sweepstakes, in addition to any other rights which may be granted in any other agreement entered into between Sponsor and any Entrant of the Sweepstakes, each Entrant irrevocably grants the Promotion Entities and their respective successors, assigns, and licensees, the right to use such Entrant’s name, likeness, and biographical information in any and all media for any purpose, including without limitation, advertising and promotional purposes as well as in, on or in connection with, the Sweepstakes or other promotions, and hereby release the Promotion Entities from any liability with respect thereto. Each Entrant also agrees to provide express permission for Sponsor and the Promotion Entities to publicize the Entrant’s participation in the Sweepstakes.'
            }
            <br></br>
            <br></br>
            {
              'NO OBLIGATION TO USE: Sponsor shall have no obligation (express or implied) to use any Submission, or to otherwise exploit any Submission or, if commenced, to continue the distribution or exploitation thereof, and Sponsor may at any time abandon the use of any Submission for any reason, with or without legal justification or excuse, and Entrants shall not be entitled to any damages or other relief by reason thereof.'
            }
          </SmallText>
        </Box>
      </Container>
    </ModalWrapper>
  );
};
