export const getTwitterLink = (score: string): string => {
  return `https://twitter.com/intent/tweet?url=https%3A%2F%2Fbit.ly%2F3m3QFI5&via=bumperfinance&text=I%20just%20played%20Bumper%27s%20Crypto%20Defender%20game%2C%20and%20protected%20my%20%23ETH%20from%20marauding%20%23volatility%20aliens.%20I%20scored%20${score}.%20Can%20you%20beat%20me%3F&hashtags=bumper%2C%20cryptodefender`;
};

export const getWhatsappLink = (score: string): string => {
  return `https://wa.me/?text=I just played Bumper's Crypto Defender game, and protected my ETH from marauding volatility aliens. I scored ${score}. Can you beat me? https://bit.ly/3m3QFI5`;
};

export const getTelegramLink = (score: string): string => {
  return `https://telegram.me/share/url?url=https://bit.ly/3m3QFI5&text=I just played Bumper's Crypto Defender game, and protected my ETH from marauding volatility aliens. I scored ${score}. Can you beat me? https://bit.ly/3m3QFI5
    `;
};
