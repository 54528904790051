import {
  arrayUnion,
  doc,
  getDoc,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';

import { Collections } from '../commons/collections';
import { IGameId } from '../commons/interfaces/game.interface';
import { IUser } from '../commons/interfaces/user.interface';
import { firestoreDB, usersCollection } from '../firebase';

export class UserService {
  public static async getUserByEmail(
    email: string,
  ): Promise<IUser | undefined> {
    try {
      const docRef = doc(usersCollection, email);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        return undefined;
      }

      return docSnap.data();
    } catch (err) {
      console.error(`getUserByEmail: ${err}`);
      throw err;
    }
  }

  public static async addUser(
    user: Omit<IUser, 'createdAt' | 'notificationSent'>,
  ) {
    try {
      if (!user?.email) {
        throw new Error('User email missing');
      }

      const existingUser = await UserService.getUserByEmail(user.email);

      if (existingUser) {
        throw new Error('User already exists');
      }

      const payload = {
        ...user,
        notificationSent: false,
        createdAt: Timestamp.fromDate(new Date()),
      };

      await setDoc(doc(usersCollection, user.email), payload);
    } catch (error) {
      console.error(`addUser: ${error}`);
      throw error;
    }
  }

  public static async addGameIdToUser(user: IUser, gameId: IGameId) {
    try {
      if (!user?.email) {
        throw new Error('User email missing');
      }

      if (!gameId) {
        throw new Error('Game id missing');
      }

      const userDocRef = doc(usersCollection, user.email);
      await updateDoc(userDocRef, {
        games: arrayUnion(gameId),
      });
    } catch (err) {
      console.error(`addgameIdToUser: ${err}`);
      throw err;
    }
  }

  public static async updateUser(
    email: string,
    updateObject: Partial<IUser>,
  ): Promise<void> {
    try {
      const docRef = doc(firestoreDB, Collections.USER, email);
      await updateDoc(docRef, updateObject);
    } catch (err) {
      console.error(`updateUser : ${err}`);
      throw err;
    }
  }
}
